<template>
  <div>
    <b-row class-name="match-height">
      <b-col
        lg="7"
        md="6"
      >
        <welcome-card />
      </b-col>

      <b-col
        v-if="$can('write', 'Employee')"
        lg="5"
        md="6"
      >
        <b-card class-name="card-congratulation-medal">
          <h5>You have Admin Privileges 🎉</h5>
          <b-card-text class-name="font-small-3">
            You have access to User and Show Management.
          </b-card-text>
        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol,
} from 'bootstrap-vue'
import WelcomeCard from '@/views/apps/my-profile/WelcomeCard.vue'

export default {
  components: {
    WelcomeCard,
    BCard,
    BCardText,
    BRow,
    BCol,
  },
}
</script>

<style>

</style>
